<template>
  <!-- <div
    :class="{
      'bg-transparent text-softGrey': isTransparent,
      'bg-white text-primary': !isTransparent,
    }"
    class="w-full mx-auto text-white flex items-center justify-center fixed-container top-8 md:top-9 z-10"
  > -->
  <div
    class="w-full mx-auto bg-white text-primary flex items-center justify-center fixed-container top-8 md:top-9 z-5"
  >
    <ul
      class="w-full md:w-1024 px-4 lg:px-0 py-5 font-semibold flex justify-between items-center"
    >
      <li class="w-2/12 cursor-pointer">
        <img
          class="md:h-10"
          src="@/assets/images/neo-nexgen-blue.png"
          alt="neo-nexgen-blue"
          @click="$router.push('/')"
        />
      </li>
      <li
        class="hidden lg:block cursor-pointer relative"
        v-for="(item, index) in topNavItems"
        :key="item.name"
        @mouseover="showDropdown(item.name)"
        @mouseleave="hideDropdown(item.name, true)"
      >
        <router-link :to="item.link" exact :key="index">
          {{ item.name }}
        </router-link>
        <div v-if="item.showDropdown">
          <ul
            v-if="!isSubItemsEmpty(item)"
            class="sub-menu w-max text-pulpBlue bg-softWhite shadow rounded-lg p-4"
          >
            <RouterLinkVue
              v-for="(subItem, index) in item.subItems"
              :key="index"
              :to="subItem.link"
              class="link"
            >
              <div class="mb-2">
                <div class="h-30 flex items-center justify-center">
                  <component
                    :is="subItem.icon"
                    class="bg-softGrey rounded-md w-10 h-10 p-2 mr-2"
                  />
                  <div class="flex flex-col">
                    <span class="text-sm">{{ subItem.name }}</span>
                    <span class="text-xs text-black font-light">{{
                      subItem.caption
                    }}</span>
                  </div>
                </div>
              </div>
            </RouterLinkVue>
          </ul>
        </div>
      </li>
      <li class="cursor-pointer">
        <!-- <MenuIconVue
          v-if="isTransparent"
          iconColor="#fff"
          height="23"
          width="23"
          @click.native="showModal = true"
        /> -->
        <MenuIconVue
          iconColor="#29396A"
          height="23"
          width="23"
          @click.native="showModal = true"
        />
      </li>
      <BasicModalsVue :show="showModal" @update:show="showModal = $event">
        <CloseIconVue
          :width="20"
          :height="20"
          iconColor="#111"
          class="absolute top-7 right-10 md:right-20 cursor-pointer"
          @click.native="showModal = false"
        />
        <div
          class="w-full md:w-9/12 px-4 md:px-0 py-14 mx-auto flex flex-col md:flex-row justify-between"
        >
          <div
            v-for="(data, i) in navMenu"
            :key="i"
            class="mt-10 md:mt-0 md:ml-4 text-left text-black"
          >
            <h5 class="font-extrabold text-lg">
              <router-link
                :to="data.title.url"
                @click.native="showModal = false"
                >{{ data.title.name }}</router-link
              >
            </h5>
            <ul :class="{ '-mt-5': data.title.header }">
              <RouterLinkVue
                v-for="(nav, index) in data.navs"
                :key="index"
                :to="nav.url"
                @click.native="showModal = false"
              >
                <li
                  class="my-4 text-sm font-medium cursor-pointer"
                  :class="{ 'font-extrabold text-lg': nav.header }"
                >
                  {{ nav.name }}
                </li>
              </RouterLinkVue>
            </ul>
          </div>
        </div>
        <div class="w-full md:w-9/12 px-4 md:px-0 mx-auto relative">
          <!-- <RoundedButtonVue
            title="Become an Agent"
            bgColor="bg-primary"
            textColor="text-white"
            width="w-max"
          /> -->
          <h5 class="text-black font-normal text-xs absolute -bottom-5">
            © {{ currentYear }} neo. All rights reserved.
          </h5>
        </div>
      </BasicModalsVue>
    </ul>
  </div>
</template>

<script>
import RoundedButtonVue from "../buttons/RoundedButton.vue";
import BasicModalsVue from "../modals/BasicModals.vue";
import CertificationIcon from "../svgicons/navicons/CertificationIcon.vue";
import CloseIconVue from "../svgicons/CloseIcon.vue";
import ElementaryIconVue from "../svgicons/navicons/ElementaryIcon.vue";
import MenuIconVue from "../svgicons/MenuIcon.vue";
import MiddleIconVue from "../svgicons/navicons/MiddleIcon.vue";
import ClassroomIconVue from "../svgicons/navicons/ClassroomIcon.vue";
import CollageIconVue from "../svgicons/navicons/CollageIcon.vue";
import HigherIconVue from "../svgicons/navicons/HigherIcon.vue";
import HighIconVue from "../svgicons/navicons/HighIcon.vue";
import IndependentIconVue from "../svgicons/navicons/IndependentIcon.vue";
import MediaIconVue from "../svgicons/navicons/MediaIcon.vue";
import NeoagentIconVue from "../svgicons/navicons/NeoagentIcon.vue";
import NeoassessIconVue from "../svgicons/navicons/NeoassessIcon.vue";
import NeouniversalIconVue from "../svgicons/navicons/NeouniversalIcon.vue";
import ProfessionalIconVue from "../svgicons/navicons/ProfessionalIcon.vue";
import RouterLinkVue from "../RouterLink.vue";

export default {
  props: {
    targetDivClass: {
      type: String,
      required: true,
    },
  },
  components: {
    MenuIconVue,
    BasicModalsVue,
    CloseIconVue,
    RoundedButtonVue,
    CertificationIcon,
    ElementaryIconVue,
    MiddleIconVue,
    ClassroomIconVue,
    CollageIconVue,
    HigherIconVue,
    HighIconVue,
    IndependentIconVue,
    MediaIconVue,
    NeoagentIconVue,
    NeoassessIconVue,
    NeouniversalIconVue,
    ProfessionalIconVue,
    RouterLinkVue,
  },
  data() {
    return {
      isTransparent: true,
      showModal: false,
      currentYear: new Date().getFullYear(),
      topNavItems: [
        {
          name: "Home",
          link: "/",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Teachers",
          link: "/teacher",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Admin",
          link: "/admin",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Human Resources",
          link: "/humanresources",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Nurses",
          link: "/nurses",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Students",
          link: "/students",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Resources",
          link: "#",
          showDropdown: false,
          subItems: [
            // {
            //   name: "neoAgent",
            //   caption: "neoAgent page",
            //   link: "/neoagentresources",
            //   icon: "CertificationIcon",
            // },
            {
              name: "Media",
              caption: "Media page",
              link: "/mediageneral",
              icon: "CertificationIcon",
            },
            {
              name: "neo Student Certificate Verification",
              caption: "neo Student Certificate Verification",
              link: "/neostudentcertificateverification",
              icon: "CertificationIcon",
            },
            // {
            //   name: "DynEd Student Certificate Verification",
            //   caption: "DynEd Student Certificate Verification",
            //   link: "/dynedstudentcertificateverification",
            //   icon: "CertificationIcon",
            // },
            {
              name: "Statement of Results Verification",
              caption: "Statement of Results Verification",
              link: "https://neo-verify.com",
              icon: "CertificationIcon",
            },
            {
              name: "System Requirements",
              caption: "System Requirements",
              link: "/support",
              icon: "CertificationIcon",
            },
          ],
        },
        {
          name: "About Us",
          link: "/about",
          showDropdown: false,
          subItems: [],
        },
        {
          name: "Contact Us",
          link: "/contactus",
          showDropdown: false,
          subItems: [],
        },
      ],
      navMenu: [
        {
          title: { name: "Teachers", url: "teacher", header: false },
          navs: [
            { name: "Preschool", url: "preschool", header: false },
            {
              name: "Elementary School",
              url: "elementaryschool",
              header: false,
            },
            { name: "Middle School", url: "middleschool", header: false },
            { name: "High School", url: "highschool", header: false },
            { name: "Higher Learning", url: "higherlearning", header: false },
          ],
        },
        {
          title: { name: "Students", url: "students", header: false },
          navs: [
            {
              name: "School or University User",
              url: "schooluser",
              header: false,
            },
            { name: "Business User", url: "businessuser", header: false },
          ],
        },
        {
          title: { name: "", url: "#", header: true },
          navs: [
            { name: "Admin", url: "admin", header: true },
            { name: "Nurses", url: "nurses", header: true },
            { name: "Human Resources", url: "humanresources", header: true },
            { name: "Contact Us", url: "contactus", header: true },
            { name: "About Us", url: "about", header: true },
          ],
        },
        {
          title: { name: "Resources", url: "#", header: false },
          navs: [
            // { name: "neoAgent", url: "neoagentresources", header: false },
            { name: "Media", url: "mediageneral", header: false },
            {
              name: "neo Student Certificate Verification",
              url: "neostudentcertificateverification",
              header: false,
            },
            // {
            //   name: "DynEd Student Certificate Verification",
            //   url: "/dynedstudentcertificateverification",
            //   header: false,
            // },
            {
              name: "Statement of Results Verification",
              url: "https://neo-verify.com/",
              header: false,
            },
            {
              name: "System Requirements",
              url: "support",
              header: false,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll(); // Initial check on mount to set the background correctly
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isSubItemsEmpty(item) {
      return item.subItems.length === 0;
    },
    showDropdown(itemName) {
      const item = this.topNavItems.find(
        (navItem) => navItem.name === itemName
      );
      if (item) {
        item.showDropdown = true;
      }
    },
    hideDropdown(itemName, withDelay) {
      const item = this.topNavItems.find(
        (navItem) => navItem.name === itemName
      );
      if (item) {
        if (withDelay) {
          // Delay the hiding of the dropdown for a short time
          setTimeout(() => {
            item.showDropdown = false;
          }, 5000); // Adjust the delay time as needed
        } else {
          item.showDropdown = false;
        }
      }
    },
    handleScroll() {
      const targetDivs = document.getElementsByClassName(this.targetDivClass);

      this.isTransparent = Array.from(targetDivs).some((targetDiv) => {
        const targetRect = targetDiv.getBoundingClientRect();
        const menuRect = this.$el.getBoundingClientRect();

        // Check if the menu is inside the target div's range vertically
        return (
          menuRect.top >= targetRect.top && menuRect.bottom <= targetRect.bottom
        );
      });
    },
  },
};
</script>

<style>
/* .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.nav-item:hover .dropdown-menu {
  display: block;
} */

.fixed-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sub-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position the sub-menu below the parent item */
  left: -70px;
  max-width: 320px;
  z-index: 999;
}

li:hover .sub-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.sub-menu:hover {
  display: block;
}

.router-link-active {
  color: #49c5fe;
}
</style>